@use "./reset";

// @import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);


$txt-color: #1D4FA1;

// ブレイクポイント
$pc: 1100px;
$tab: 820px;
$sp: 420px;

@mixin pc {
  @media screen and (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media screen and (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: ($sp)) {
    @content;
  }
}

html {
  scroll-behavior: smooth;
}

body{
  font-family: 'Noto Sans JP', sans-serif, "Segoe UI", Verdana, "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  word-wrap: break-word;
  // word-break: break-all;
}
// section{
//   padding-bottom: 100px;
//   @include sp{
//     padding-bottom: 50px;
//   }
// }

.wrap{
  max-width: 1140px;
  margin: 0 auto;
  width: 96%;
}
img{
  width: 100%;
  height: auto;
}
h1 {
  font-size: clamp(1.75rem, 1.04rem + 3.11vw, 3.375rem);
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  line-height: 2.5;
  letter-spacing: 0.03em;
  @include pc {
    line-height: 2;
  }
  @include sp {
    font-size: 15px;
  }
}
a {
  font-size: 20px;
  &:hover {
    text-decoration: underline;
  }
  @include pc {
    font-size: 18px;
  }
}
.ttl-wrap {
  position: relative;
  min-height: 130px;
  background-position-x: 45px;
  background-size: contain;
  background-repeat: no-repeat;
  @include pc {
    min-height: 95px;
    background-position-x: 30px;
  }
  @include sp {
    min-height: 60px;
    max-width: 175px;
    margin-top: 20px;
  }

  .ttl-ja {
    font-size: clamp(1.625rem, 1.243rem + 1.68vw, 2.5rem);
    color: $txt-color;
    position: absolute;
    bottom: 20px;
    @include pc {
      bottom: 15px;
    }
    @include sp {
      bottom: 7px;
    }
  }
}
.arrow {
  display: inline-block;
  padding: 1px 2px;
  text-decoration: none;
  font-size: 17px;
  color: #143B7B;
  border: 1px solid #143B7B;
  border-radius: 50%;
  margin-right: 10px;
}
.pc-none {
  @include pc {
    display: none;
  }
}
.tab-center {
  @include tab {
    text-align: center;
  }
}
.tab-block {
  display: none;
  @include tab {
    display: block;
  }
}
.tab-none {
  @include tab {
    display: none;
  }
}
.sp-block {
  display: none;
  @include sp {
    display: block;
  }
}
.sp-inline-block {
  display: none;
  @include sp {
    display: inline-block;
  }
}
.sp-none {
  @include sp {
    display: none;
  }
}



header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background: linear-gradient(90deg, #0070a2 0%, #011950 100%);
  height: 100px;
  @include sp {
    height: 70px;
  }

  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .header-logo {
      max-width: 165px;
      min-width: 105px;
      width: 20%;
      height: auto;
      @include tab {
        width: 30%;
      }
      
      a {
        display: block;
        height: 100%;

        img {
          height: 100%;
        }
      }
    }
  
    .header-right {
      width: 80%;
      height: 100%;
      
      ul {
        display: flex;
        justify-content: end;
        align-items: center;
        height: 100%;
    
        li {
          margin-right: 24px;
          height: 100%;
          // padding: 0 10px;
          &:hover {
            background: #06639D;

            ul {
              display: block;
            }
          }
          @include pc {
            margin-right: 20px;
          }
          @include tab {
            display: none;
          }
  
          a {
            color: white;
            display: flex;
            height: 100%;
            align-items: center;
            padding: 0 20px;
            &:hover {
              text-decoration: none;
              background: #06639D;
              .triangle {
                transform: rotateZ(-180deg);
                // transform:rotate3d(1,1,1,180deg);
              }
            }
  
            img {
              max-width: 38px;
              margin-right: 10px;
              height: fit-content;
              @include pc {
                max-width: 30px;
              }
            }
  
            .header-txt {
              display: flex;
              flex-direction: column;
              font-size: clamp(0.75rem, 0.211rem + 1.05vw, 1rem);
  
              .header-txt-en {
                font-size: 12px;
                font-family: 'Roboto', sans-serif;
                font-weight: bold;
                letter-spacing: 0.1em;
                color: #D0D8E0;
                @include pc {
                  font-size: 10px;
                }
              }
  
              .header-txt-ja {
                display: flex;
                // transform-style: preserve-3d;
                // perspective: 10px;
  
                .triangle {
                  margin-left: 10px;
                  align-self: center;
                  width: 0;
                  height: 0;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  border-top: 5px solid white;
                  transition: all .3s ease;
                }
              }
            }
          }

          ul {
            display: none;
            background: #06639D;
            height: fit-content;
            width: 100%;
            padding: 0 15px;

            li {
              height: fit-content;
              margin: 0 auto 15px;
              text-align: left;
              &:hover {
                opacity: 0.8;
              }
              
              &:last-of-type {
                padding-bottom: 20px;
              }
              
              a {
                position: unset;
                display: flex;
                height: fit-content;
                font-size: clamp(0.625rem, 0.534rem + 0.45vw, 0.875rem);
                padding: 0;
                margin-left: 3px;

                img {
                  max-width: 4px;
                }
              }
            }
            .sub-menu-ttl {
              color: white;
              border-bottom: 1px solid white;
              padding-bottom: 5px;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
  
        .my-page {
          min-width: 175px;
          padding: 0;
          &:hover {
            background: unset;
          }
          @include tab {
            display: block;
            &:hover {
              ul {
                display: none;
              }
            }
          }
          .my-page-link {
            justify-content: center;
          }
          
          a {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            max-height: 70px;
            background: white;
            border-radius: 3px;
            color: $txt-color;
            padding: 15px;
            &:hover {
              color: #2485C1;
              background: #F5F9FF;

              .triangle {
                border-top: 5px solid #2485C1;
              }

              .header-txt-en {
                color: #2485C1 !important;
              }
            }
            @include tab {
              display: none;
            }
  
            .header-txt-en {
              color: $txt-color !important;
            }

            .triangle {
              margin-left: 10px;
              align-self: center;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #01255B;
              transition: all .3s ease;
            }
          }
          .fa-circle-user {
            align-self: center;
            margin-right: 10px;
          }

          ul {
            background: white;
            border-radius: 4px;
            margin-top: 28px;
            padding-top: 18px;
            padding-bottom: 18px;
            @include tab {
              display: none;
            }

            li {
              line-height: 1.3;
              
              &:hover {
                background: white;
              }

              &:last-of-type {
                padding-bottom: 0;
                margin-bottom: 0;
              }

              a {
                color: #2485C1;
                padding: 0;
                display: flex;
                transform: translateY(0);
                &:hover {
                  background: white;
                }

                img {
                  max-width: 4px;
                }
              }
            }
            
            .account-list-trigger { 
              &:hover {
                .account-list {
                  display: block;
                }

                img {
                  transition: all .3s ease;
                  transform: rotateZ(90deg);
                }
              }

              .event-none {
                cursor: default;
                pointer-events: none;
              }
              
              .account-list {
                display: none;
                margin-top: 0;
                padding: 10px 0 10px;
                margin-left: -5px;
                // height: 275px;
                // overflow-y: scroll;
                // &::-webkit-scrollbar {
                //   display: none;
                // }
  
                li {
                  margin-bottom: 12px;
                  // margin-left: 5px;
                  &:first-of-type {
                    margin-top: 7px;
                  }
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                  
                  a {
                    font-size: clamp(0.75rem, 0.528rem + 0.46vw, 0.875rem);
                    &::before {
                      content: "・";
                      margin-right: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sp-img {
      display: none;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100px;
      @include tab {
        display: block;
        max-width: 100px;
      }
      @include sp {
        max-width: 72px;
      }

      a {
        display: block;
        background: unset;
        width: 100%;
        min-width: 70px;

        img {
          width: 100%;
          min-width: 70px;
        }
      }
    }
  }
}
//============================== END OF HEADER ==============================//


.header_right-sp {
  display: none;
  @include tab{
    display: block;
  }
}
.gnav {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 100%;
  // background-color: #142b55;
  background: linear-gradient(130deg, #0070a2 0%, #011950 100%);
  z-index: 9999;
  transition : all .8s ease;
  opacity: 0;
  overflow: scroll;
  &::before{
    content: "";
    // background: rgba(255, 255, 255, .4);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .nav-btn{
    background: white;
    color: red;
    border-radius: 10px;
    border: none;
    margin-top: 50px;
    max-width: 240px;
    font-size: 18px;
    font-weight: bold;
    display: block;
    margin: auto;
    text-align: center;
    padding: 15px 0;
    position: relative;
    &:hover{
      opacity: 0.6;
    }
  }
}
.SP_menu.active + .gnav {
  left: 0;
  opacity: 1;
}
.gnav ul {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 110px;
  position: relative;
  margin-top: 100px;
  max-width: 500px;
  @include sp {
    margin-top: 80px;
  }
}
.gnav ul li {
  font-size: 20px;
  margin-top: 12px;
  text-align: left;
  @include sp {
    font-size: 16px;
  }
  &:last-of-type {
    ul {
      li {
        &:last-of-type {
          // width: fit-content;
          width: 100%;
        }
      }
    } 
  }
}
.ml-20 {
  display: block;
  margin: 0 auto;
  width: 94%;
  &::before {
    content: url(../../dist/images/header-arrow.png);
    vertical-align: text-top;
    padding-right: 5px;
  }
}
.white-back {
  display: block;
  text-align: center;
  background: rgba(255, 255, 255, .1);
  padding: 3px 0 4px;
}
.gnav ul li a {
  color: white;
  font-weight: 500;
}
.gnav ul li ul {
  display: flex;
  flex-wrap: wrap;
  // margin: 0 0 20px 20px;
  margin: 0 auto;
  width: 94%;
}
.gnav ul li ul li {
  width: 48%;
  margin-top: 10px;
  @include sp {
    margin-right: 2%;
  }  
}
.sp-account-list-trigger {

  span {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    display: flex;
    align-items: center;

    img {
      max-width: 4px;
      display: block;
      margin-right: 5px;
      transition: all .3s ease;
    }
    img.open {
      transform: rotateZ(90deg);
    }
  }

  .sp-account-list {
    display: none;
    li {
      width: 100%;
      a {
        font-size: 14px;
        &::before {
          content: '・';
        }
      }
    }
  }
}
.gnav ul li ul li a {
  font-size: 16px;
  line-height: 2;
  &::before {
    content: url(../../dist/images/header-arrow.png);
    vertical-align: top;
    padding-right: 5px;
  }
  @include sp {
    font-size: 14px;
  }
}

.gnav-sub-ttl {
  color: white;
  width: 100% !important;
  border-bottom: 1px solid white;
  line-height: 2;
}
.SP_menu {
  width: 65px;
  height: 60px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -100px;
  border: 15px solid rgba(0,0,0,0);
  border-radius: 50%;
  z-index: 10000;
  right: 20px;
}
.menu-trigger,
.menu-trigger span {
  display: flex;
  transition: all .4s;
  box-sizing: border-box;
}
.menu-trigger {
  width: 30px;
  height: 30px;
}
.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  border-radius: 4px;
}
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  top: 45%;
}
.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}
.SP_menu.active .menu-trigger span:nth-of-type(1) {
  transform: translateY(19px) rotate(-45deg);
}
.SP_menu.active .menu-trigger span:nth-of-type(2) {
  opacity: 0;
}
.SP_menu.active .menu-trigger span:nth-of-type(3) {
  transform: translateY(-9px) rotate(45deg);
}
//============================== END OF HAMBURGER MENU ==============================//


footer {
  background: linear-gradient(90deg, #0070a2 0%, #011950 100%);


  .contact-area {
    padding: 25px 0;
    @include tab {
      padding: 50px 0;
    }

    .contact-contents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include tab {
        flex-direction: column;
      }
  
      .contact-item-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 520px;
        width: 100%;
        @include tab {
          flex-direction: column;
          align-items: flex-start;
          max-width: 265px;
          margin-bottom: 10px;
        }
  
        .mail-address {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:nth-of-type(2) {
            @include tab {
              margin-bottom: 0;
            }
          }
          @include tab {
            margin-bottom: 30px;
          }

          .mail-icon {
            max-width: 24px;
            margin-right: 10px;
          }

          .mail-txt {
            color: white;
            font-size: 11px;
            line-height: 1.5;

            .address {
              font-family: 'Roboto', sans-serif;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0.05em;
            }
          }
        }

        .mail-border {
          @include tab {
            display: none;
          }
        }
      }

      .contact-item-right {
        color: white;

        a {
          border: 1px solid white;
          border-radius: 3px;
          padding: 15px 35px;
          display: block;
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .footer-contents {
    background: white;
    padding: 40px 30px;
    @include tab {
      padding: 50px 30px 15px;
    }

    .footer-contents-wrap {
      max-width: 1140px;
      margin: 0 auto 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include tab {
        flex-direction: column;
      }

      .footer-logo {
        max-width: 380px;
        @include tab {
          width: 60%;
          margin-bottom: 50px;
        }
      }

      .footer-list {
        width: 70%;
        @include tab {
          width: 100%;
        }
        
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 480px;
          margin-left: auto;
          margin-right: 0;
          @include tab {
            max-width: unset;
          }

          li {
            width: 49%;
            @include tab {
              width: 100%;
            }
            
            a{
              display: block;
              font-size: 13px;
              font-weight: bold;
              width: 100%;
              max-width: 200px;
              border-bottom: 1px dashed rgba(132, 139, 152, 0.2);
              padding-bottom: 15px;
              margin-bottom: 15px;
              color: #142B55;
              @include tab {
                max-width: unset;
              }
  
              .triangle {
                font-size: 12px;
                margin-right: 15px;
                color: #142b55;
                transform: rotateZ(90deg);
                display: inline-block;
              }
            }

            &:last-of-type {
              width: 49%;
            }
          }
          
          .footer-list-img {
            display: flex;
            align-items: center;
            @include sp {
              flex-direction: column;
              align-items: baseline;
            }

            img {
              display: block;

              &:first-of-type {
                max-width: 110px;
                margin-right: 30px;
                width: 49%;
                @include tab {
                  width: 100%;
                }
                @include sp {
                  max-width: unset;
                  width: 200px;
                }
              }

              &:nth-of-type(2) {
                max-width: 145px;
                max-height: 45px;
                width: 49%;
                @include tab {
                  width: 100%;
                }
                @include sp {
                  max-width: unset;
                  max-height: unset;
                  width: 250px;
                }
              }
            }
          }
        }

        .cert-logos {
          display: flex;
          justify-content: right;
          align-items: center;

          .cert-logo {
            max-width: 250px;
            margin: 0 10px;
            &:first-of-type {
              max-width: 180px;
            }
          }
        }
      }
    }

    .copy-right {
      text-align: center;
      font-size: 12px;
      color: #0A2B58;
      @include tab {
        font-size: 10px;
      }
    }
  }
}
//============================== END OF FOOTER ==============================//


/* パンくずリスト用CSS */
.breadcrumb{
  // background: #87A94F;
  padding: 20px;
  // margin-top: -60px;
  margin-bottom: 20px;
  // @media screen and (max-width: 950px) {
  //   margin-top: -60px;
  // }
  @include sp{
    padding: 10px 15px;
  }

  #breadcrumb{
    margin:auto;
    font-size: 18px;
    font-weight: 500;
    max-width: 1100px;
    width: 100%;
    @include sp{
      font-size: 14px;
      width: 100%;
    }

    ul {
        color: #fff;
        text-align: left;

      li{
        display:inline;/*横並びにする*/
        line-height:20px;/*行間*/
        font-size: 14px;
        margin-right: 5px;
        @include sp {
          font-size: 12px;
        }
  
        a{
          // text-decoration: underline;
          font-size: 14px;
          @include sp {
            font-size: 12px;
          }

          span {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
/*======================= END OF BREADCRUMB =======================*/

.top-btn {
  position: fixed;
  bottom: 30px;
  right: 10px;
  max-width: 60px;
  opacity: 0.8;
}