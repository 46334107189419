@use "./reset";
@use "./_common" as *;


.sec-mv {
  height: 100vh;
  background:  linear-gradient(0deg, rgba(0, 63, 149, 0.73) 0%, rgba(255, 255, 255, 0.33) 100%);
  // background:  linear-gradient(0deg, rgba(0, 63, 149, 0.73) 0%, rgba(255, 255, 255, 0.33) 100%), url(../images/mv/mv.png);
  // background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  @include sp {
    height: 600px;
  }

  .video-area {
    position: absolute;
    z-index: -1;/*最背面に設定*/
    top: 0;
    right:0;
    left:0;
    bottom:0;
    overflow: hidden;

    video {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /*縦横幅指定*/
      width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
      height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
      min-height: 100%;
      min-width: 100%;
    }
  }

  .mv-wrap {
    .mv-txtarea {
      position: absolute;
      bottom: 10%;
      color: white;
      
      p {
        margin: 5px 0 25px;
      }

      a {
        display: block;
        margin-top: 25px;
        &:hover {
          .arrow {
            background: rgba(240, 240, 240, 0.4);
          }
        }

        .arrow {
          color: white;
          border-color: white;
        }
      }
    }
  }
}
//============================== END OF MV ==============================//


.sec-benefit {
  background-image: url(../images/benefit.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include tab {
    background-image: none;
  }
}
.benefit-wrap {
  display: flex;
  max-width: unset;
  width: 100%;
  @include tab {
    flex-direction: column-reverse;
  }

  .benefit-left {
    width: 50%;
    padding: 125px 75px 135px;
    color: white;
    background: rgba(9,43,98,0.93);
    @include tab {
      width: 100%;
      padding: 55px 35px;
    }

    .benefit-left-txtarea {
      max-width: 490px;
      margin-right: 0;
      margin-left: auto;

      h2 {
        font-size: clamp(1.5rem, 1.118rem + 1.68vw, 2.375rem);
        line-height: 1.7;
        margin-bottom: 55px;
      }
  
      p {
        margin-bottom: 45px;
      }

      a {
        display: block;
        text-align: right;
        width: fit-content;
        margin: 0 0 0 auto;
        &:hover {
          .arrow {
            background: rgba(240, 240, 240, 0.4);
          }
        }
        
        .arrow {
          color: white;
          border-color: white;
        }
      }
    }
    }


  .benefit-right {
    width: 50%;
    @include tab {
      width: 100%;
    }
  }
}
//============================== END OF BENEFIT ==============================//


.sec-service {
  margin: 80px 0 0;

  .service-wrap {
    // max-width: 1530px;
    // width: 100%;
    // margin-left: 0;
    // margin-right: auto;
    @include tab {
      padding: 0 15px;
    }

    .service-contents {
      display: flex;
      flex-direction: column;

      .service-item-left {
        width: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .service-item-right {
        width: 100%;
        padding: 0 40px 20px;
        @include tab {
          padding: 0 25px 20px;
        }
        @include tab {
          width: 100%;
          padding: 0;
        }

        .ttl-wrap {
          background-image: url(../images/service-en.png);
        }

        .service-sub-ttl {
          background: #1D4FA1;
          color: white;
          font-size: clamp(1.125rem, 0.989rem + 0.68vw, 1.5rem);
          font-weight: bold;
          box-shadow: 3px 3px 8px rgba(199, 212, 232, 0.45);
          padding: 30px 45px;
          margin: 50px 0 30px;
          @include sp {
            padding: 30px 20px;
            margin-bottom: 12px;
          }
        }

        .service-links {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          @include sp {
            flex-direction: column;
          }
        }
        
        .service-link {
          width: 48%;
          margin: 15px 0;
          @include sp {
            width: 100%;
            margin: 12px 0;
            // &:first-child {
            //   @include sp {
            //     margin-top: 0;
            //   }
            // }
          }
        }

        a {
          padding: 30px 25px;
          display: block;
          font-weight: bold;
          color: $txt-color;
          box-shadow: 3px 3px 8px rgba(199, 212, 232, 0.45);
          &:hover {
            .arrow {
              color: white;
              background: #143B7B;
            }
          }
          @include sp {
            padding: 25px 20px;
          }
        }
      }
    }
  }
}
//============================== END OF SERVICE ==============================//


.sec-movie {
  margin-top: 100px;
  margin-bottom: 120px;
  // @include pc {
  //   margin-top: 160px;
  // }
  @include sp {
    margin: 30px 0 40px;
  }

  .movie-wrap {
    max-width: unset;
    width: 100%;
    @include sp {
      padding: 0 15px;
    }

    .movie-contents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1900px;
      margin-left: auto;
      margin-right: auto;
      @include sp {
        flex-direction: column;
        align-items: end;
      }

      .movie-img-left {
        width: 19%;
        max-width: 365px;
        position: relative;
        bottom: -100px;
        @include sp {
          bottom: 15px;
          width: 100%;
          max-width: 130px;
          display: none;
        }
      }

      .movie {
        width: 60%;
        @include sp {
          width: 100%;
        }
      }

      .movie-img-right {
        width: 19%;
        max-width: 365px;
        position: relative;
        top: -100px;
        @include sp {
          top: 15px;
          align-self: start;
          width: 100%;
          max-width: 130px;
          display: none;
        }
      }

      //_______________ フェードイン設定 _______________//
      .fadein {
        opacity: 0;
        transition: all 1000ms;
      }
      .fadein.scrollin {
        opacity: 1;
      }
    }
  }
}
//============================== END OF MOVIE ==============================//


.sec-info {
  background-image: url(../images/information-back.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .info-wrap {
    padding: 65px 0 100px;
    background: #FAFAFA;
    @include sp {
      padding-bottom: 55px;
    }

    .info-area {
      height: 100%;
      width: 100%;
      padding: 0 50px;
      @include sp {
        padding: 0 15px;
      }

      .ttl-wrap {
        background-image: url(../images/info-en.png);
        margin-bottom: 50px;
        @include sp {
          max-width: unset;
        }
      }

      .info-list {
        
        .info-item {          
          border-bottom: 1px solid #143B7B;
          padding-bottom: 25px;
          margin-bottom: 20px;

          .info-detail {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .info-cate {
              pointer-events: none;
              a {
                width: fit-content;
                padding: 2px 15px;
                border-radius: 3px;
                font-size: 13px;
                font-weight: bold;
                margin-right: 15px;
                cursor: unset;
                &:hover {
                  text-decoration: none;
                }
              }
            }
            .info-cate.event {
              a {
                color: #5BB43A;
                border: 1px solid #5BB43A;
              }
            }
            .info-cate.news {
              a {
                color: #00BFB2;
                border: 1px solid #00BFB2;
              }
            }

            .info-date {
              color: #93A6BE;

              .new {
                margin-left: 15px;
                font-weight: 500;
                color: #DE0000;
              }
            }
          }

          .info-ttl {
            color: #011950;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.7;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .arrow {
              position: relative;
              display: inline-block;
              padding: 10px;
              border: unset;
              @include sp {
                display: none;
              }

              &::before {
                content: '';
                width: 13px;
                height: 13px;
                border-top: solid 2px #143B7B;
                border-right: solid 2px #143B7B;
                position: absolute;
                left: 0;
                top: 8px;
                transform: rotate(45deg);
              }
            }
          }
          
          .info-contents {
            color: #333333;
            
            p {
              line-height: 1.73;
              width: 90%;
            }
          }
        }
      }

      .to-news-list {
        font-weight: bold;
        color: #143B7B;
        display: block;
        text-align: right;
        width: fit-content;
        margin: 0 0 0 auto;
        &:hover {
          .arrow {
            color: white;
            background: #143B7B;
          }
        }
        @include sp {
          margin: 0 auto 0 0;
        }

        .arrow {
          color: #143B7B;
          border: 1px solid #143B7B;
        }
      }
    }
  }
}
//============================== END OF INFORMATION ==============================//


.sec-group {
  position: relative;
  background: linear-gradient(#011950 0%, #0070a2 100%);
  padding: 30px 0 50px;
  z-index: 0;
  &::before {
    content: "";
    background-image: url(../../dist/images/group-back.png);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    @media screen and (max-width: 1200px) {
      // background-image: url(../../dist/images/group-back-sp.png);
    }
  }

  .group-wrap {
    .ttl-wrap {
      background-image: url(../images/group-en.png);
      background-position: center;
      margin-bottom: 50px;
      @include sp {
        max-width: unset;
      }

      .ttl-ja {
        color: #FFFFFF;
        width: 100%;
        text-align: center;
      }
    }
    
    .group-map-area {
      
      .nation {
        font-family: 'Roboto';
        font-weight: 500;
        font-style: italic;
        font-size: 16px;
        letter-spacing: 0.02em;
        line-height: 1.7;
        text-align: left;
        color: #fff;
        
        a {
          font-family: 'Roboto';
          font-weight: 500;
          font-style: italic;
          font-size: 16px;
          letter-spacing: 0.02em;
          line-height: 1.7;
          text-align: left;
          color: #fff;
        }
      }
  
      .city {
        font-family: 'Roboto';
        font-weight: 300;
        font-style: italic;
        font-size: 15px;
        letter-spacing: 0.08em;
        text-align: left;
        color: #fff;            
      }

      .group-map {
        position: relative;
        @media screen and (max-width: 1200px) {
          display: none;
        }

        .branch  {
          position: absolute;


          .map-pin {
            max-width: 50px;
            position: relative;
          }
        }
        .europe {
          top: 90px;
          left: 115px;
          &:hover {
            .e-pin {
              display: none;
            }
            .e-pin-b {
              display: block !important;
            }
          }
          
          .map-pin {
            top: -15px;
            left: -65px;

            .e-pin-b {
              display: none;
            }
          }
        }
        .spain {
          top: 195px;
          left: 85px;
                    
          .map-pin {
            top: -70px;
            left: -70px;
          }
        }
        .Headquarters {
          top: 115px;
          left: 475px;
          &:hover {
            .h-pin {
              display: none;
            }
            .h-pin-y {
              display: block !important;
            }
          }
                    
          .map-pin {
            top: 10px;
            left: -50px;

            .h-pin-y {
              display: none;
            }
          }
        }
        .japan {
          top: 210px;
          left: 535px;
          &:hover {
            .j-pin {
              display: none;
            }
            .j-pin-b {
              display: block !important;
            }
          }
                    
          .map-pin {
            top: -70px;
            left: -70px;

            .j-pin-b {
              display: none;
            }
          }
        }
        .singapore {
          top: 298px;
          left: 418px;
          &:hover {
            .s-pin {
              display: none;
            }
            .s-pin-b {
              display: block !important;
            }
          }
                    
          .map-pin {
            top: -50px;
            left: -70px;

            .s-pin-b {
              display: none;
            }
          }
        }
        .oceania {
          top: 405px;
          left: 350px;
                    
          .map-pin {
            top: -30px;
            left: 135px;
          }
        }
        .psomagen {
          top: 135px;
          left: 760px;
          &:hover {
            .p-pin {
              display: none;
            }
            .p-pin-b {
              display: block !important;
            }
          }
                    
          .map-pin {
            top: -25px;
            left: 150px;

            .p-pin-b {
              display: none;
            }
          }
        }
        .chile {
          top: 400px;
          left: 800px;
                    
          .map-pin {
            top: -45px;
            left: 140px;
          }
        }
      }

      .group-map-sp {
        display: none;
        @media screen and (max-width: 1200px) {
          display: block;
        }
        
        .branch-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .branch-item {
            width: 48%;
            max-width: 400px;
            margin: 0 auto 20px;
            border: 1px solid white;
            border-radius: 55px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 25px;
            @include tab {
              width: 100%;
              max-width: 305px;
            }
            @include sp {
              padding: 10px 25px 15px;
            }
            &:hover {
              background: rgba(240, 240, 240, .36);
            }

            img {
              width: 14%;
              max-width: 35px;
              max-height: 35px;
              margin-right: 10px;
            }

            .branch-item-text {
              width: 83%;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
//============================== END OF GORUP ==============================//





.sec-lists {
  padding: 100px 0;
}
//============================== END OF INFO LISTS ==============================//